const loginModal = document.getElementById('modal-login');
if (loginModal) {
    const loginFormSelects = loginModal.querySelectorAll('.form-select a[data-form]');
    const loginForms = loginModal.querySelectorAll('form');

    const toggleForm = function toggleForm(formId) {
        loginForms.forEach((loginForm) => {
            if (loginForm.id === formId) {
                loginForm.classList.add('visible');
            } else {
                loginForm.classList.remove('visible');
            }
        });
        loginFormSelects.forEach((loginFormSelect) => {
            if (loginFormSelect.dataset.form === formId) {
                loginFormSelect.classList.add('active');
            } else {
                loginFormSelect.classList.remove('active');
            }
        });
    };

    loginFormSelects.forEach((loginFormSelect) => {
        loginFormSelect.addEventListener('click', (event) => {
            event.preventDefault();
            const loginFormId = loginFormSelect.dataset.form;
            toggleForm(loginFormId);
        });
    });
}
